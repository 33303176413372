<template>
  <content-sheet>
    <gift-modal v-if="$store.state.activeModal === 0" :mobile="true" />
    <newsletter-modal v-else-if="$store.state.activeModal === 1" :mobile="true" />
    <contact-modal v-else-if="$store.state.activeModal === 2" :mobile="true" />
    <div v-else>
      <h2>Error 404</h2>
    </div>
  </content-sheet>
</template>

<script>
import ContentSheet from '../components/ContentSheet.vue'
import GiftModal from '../components/modals/GiftModal.vue'
import NewsletterModal from '../components/modals/NewsletterModal.vue'
import ContactModal from '../components/modals/ContactModal.vue'

export default {
  components: {
    ContentSheet,
    GiftModal,
    NewsletterModal,
    ContactModal
  },
}
</script>

<style>

</style>